export default {
  props: {
    hashId: {
      type: String,
      required: true,
    },

    houseAd: {
      type: Object,
      required: true,
    },

    location: {
      type: String,
      required: true,
    },

    scrollPadding: {
      type: Boolean,
      default: false,
    },

    selectedVariant: {
      type: Object,
      required: true,
    },
  },

  inject: [
    'dismiss',
    'trackClick',
    'trackView',
  ],

  data() {
    return {
      isVisible: false,
      // This value can be a scroll position in pixels, a target element's offset, or time in milliseconds.
      showAfterValue: null,
    };
  },

  mounted() {
    this.handleShowAfterBehavior();
  },

  methods: {
    handleHouseAdShowAfterPx(px) {
      this.showAfterValue = parseInt(px, 10);
      window.addEventListener('scroll', this.showHouseAdAfterPx);
      this.showHouseAdAfterPx();
    },

    handleHouseAdShowAfterTarget(selector) {
      this.showAfterValue = document.querySelector(selector);

      if (this.showAfterValue) {
        window.addEventListener('scroll', this.showHouseAdAfterTarget);
        this.showHouseAdAfterTarget();
      }
    },

    handleShowAfterBehavior() {
      const value = this.selectedVariant.show_after;

      if (value) {
        if (!Number.isNaN(parseInt(value, 10)) && value.endsWith('px')) {
          this.handleHouseAdShowAfterPx(value);
        } else if (!Number.isNaN(parseInt(value, 10)) && value.endsWith('ms')) {
          this.showHouseAdAfterTime(value);
        } else {
          this.handleHouseAdShowAfterTarget(value);
        }
      } else {
        this.showHouseAdAfterTime(500);
      }
    },

    showHouseAd() {
      if (this.$refs.houseAd) {
        this.isVisible = true;
        this.$bus.$emit('house-ad-shown', this.$refs.houseAd, this.location, this.getInitialHeight?.());
        this.trackView();
      }
    },

    showHouseAdAfterPx() {
      if (window.scrollY > this.showAfterValue) {
        this.showHouseAd();
        window.removeEventListener('scroll', this.showHouseAdAfterPx);
      }
    },

    showHouseAdAfterTarget() {
      if (window.scrollY > this.showAfterValue.offsetTop) {
        this.showHouseAd();
        window.removeEventListener('scroll', this.showHouseAdAfterTarget);
      }
    },

    showHouseAdAfterTime(ms) {
      this.showAfterValue = parseInt(ms, 10);
      setTimeout(this.showHouseAd, this.showAfterValue);
    },
  },
};
