import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueLazyload from 'vue-lazyload';
import '@lottiefiles/lottie-player';

import '@/prototypes/screen';

import VetsterHouseAd from '@/brand-components/HouseAd/VetsterHouseAd';
import VetsterButton from '@/brand-components/VetsterButton';
import VetsterInput from '@/brand-components/VetsterInput';
import VetsterNav from '@/brand-components/VetsterNav';
import VetsterReview from '@/brand-components/VetsterReview';
import VetsterButtonLegacy from '@/components/VetsterButtonLegacy';
import VetsterFooterLocales from '@/components/VetsterFooterLocales';
import VetsterInputLegacy from '@/components/VetsterInputLegacy';
import useCookies from '@/composables/use-cookies';
import useKeyboardEvents from '@/composables/use-keyboard-events';
import usePvarsStorage from '@/composables/use-pvars-storage';
import registerFontAwesome from '@/config/fontAwesome';
import registerVueTippy from '@/config/tooltips';
import { getCookie } from '@/helpers/cookies';
import { isDevelopment } from '@/helpers/environment';
import { slugCase, snakeCase } from '@/helpers/format';
import storeDeviceData from '@/helpers/storeDeviceData';
import { i18n } from '@/i18n';
import ScrollsToElement from '@/mixins/ScrollsToElement';
import TogglesHubSpot from '@/mixins/TogglesHubSpot';
import Analytics from '@/packages/Analytics';
import Api from '@/packages/Api';
import Auth from '@/packages/Auth';
import {
  fullStoryIdentify,
  fullStoryReadyCallback,
} from '@/packages/FullStory';
import Sentry from '@/packages/Sentry';
import { initStatsig } from '@/packages/Statsig';
import EventBus from '@/plugins/EventBus';
import Layout from '@/plugins/Layout';
import { installGooglePlugin } from '@/socialite/google';
import store from '@/store';

registerFontAwesome();
registerVueTippy();

if (isDevelopment()) {
  Vue.component('VetsterDebug', () => import('./components/VetsterDebug'));
}

Vue.component('VetsterHouseAd', VetsterHouseAd);
Vue.component('VetsterToast', () => import('./brand-components/VetsterToast'));

Vue.component('VetsterLoginRegister', () => import('./components/VetsterLoginRegister'));
Vue.component('VetsterAuthSlideOver', () => import('./brand-components/VetsterAuthSlideOver'));
Vue.component('VetsterAuthModalLegacy', () => import('./components/VetsterAuthModalLegacy'));
Vue.component('VetsterAuthFormsLegacy', () => import('./components/VetsterAuthFormsLegacy')); // ensures lazy loading of google auth
Vue.component('VetsterHubspotForm', () => import('./components/VetsterHubspotForm'));
Vue.component('VetsterHubspotSavingsForm', () => import('./components/VetsterHubspotSavingsForm'));
Vue.component('VetsterForVetClinicsRegisterForm', () => import('./components/VetsterForVetClinicsRegisterForm'));
Vue.component('VetsterOrganizationAuthForm', () => import('@/brand-components/VetsterOrganizationAuthForm'));

Vue.component('VetsterContactUs', () => import('./components/VetsterContactUs'));
// Vue.component('vetster-cookie-consent', () =>
//   import('./components/VetsterCookieConsent')
// );
Vue.component('VetsterContestForm', () => import('./components/VetsterContestForm'));
// TODO: Can we move this our of global? Import on demand?
Vue.component('VetsterUkForm', () => import('./components/VetsterUkForm'));
// Vue.component('VetsterPromoCodeNotification', () => import('./components/VetsterPromoCodeNotification'));
Vue.component('VetsterHolidayBanner', () => import('./components/VetsterHolidayBanner'));
Vue.component('VetsterPartner', () => import('./components/VetsterPartner'));
Vue.component('VetsterMediaHighlights', () => import('./components/VetsterMediaHighlights'));
Vue.component('VetsterLogoSlider', () => import('./components/VetsterLogoSlider'));
Vue.component('VetsterForVetsFaq', () => import('./components/VetsterForVetsFaq'));
Vue.component('VetsterForVetsTestimonials', () => import('./components/VetsterForVetsTestimonials'));
Vue.component('VetsterBlogPostLoader', () => import('./brand-components/Content/VetsterBlogPostLoader'));
Vue.component('VetsterBlogPostSlider', () => import('./components/VetsterBlogPostSlider'));
Vue.component('VetsterNavWrapper', () => import('./components/VetsterNavWrapper')); // @deprecated once we move to updated content templates
Vue.component('VetsterDynamicFilterWrapper', () => import('./components/VetsterDynamicFilterWrapper'));
Vue.component('VetsterHolidayWrapper', () => import('./components/VetsterHolidayWrapper'));
Vue.component('VetsterHorizontalScroll', () => import('./components/VetsterHorizontalScroll'));
Vue.component('VetsterMessageBubble', () => import('./brand-components/MockLiveChat/VetsterMessageBubble'));
Vue.component('VetsterMessageOptions', () => import('./brand-components/MockLiveChat/VetsterMessageOptions'));
Vue.component('VetsterMessageOptionsSkeleton', () => import('./brand-components/MockLiveChat/VetsterMessageOptionsSkeleton'));
Vue.component('VetsterMessageOptionLink', () => import('./brand-components/MockLiveChat/VetsterMessageOptionLink'));
Vue.component('VetsterNpdStatsWrapper', () => import('./components/VetsterNPDStatsWrapper'));
Vue.component('VetsterRegionSearch', () => import('./components/VetsterRegionSearch'));
Vue.component('VetsterAccordion', () => import('./components/VetsterAccordion'));
Vue.component('VetsterPPCReviewCard', () => import('./brand-components/VetsterPPCReviewCard'));
Vue.component('VetsterPPCReviews', () => import('./brand-components/VetsterPPCReviews'));
Vue.component('VetsterPPCReviewSlider', () => import('./brand-components/VetsterPPCReviewSlider'));
Vue.component('VetsterReviewSlider', () => import('./components/VetsterReviewSlider'));
Vue.component('VetsterReviewsCarousel', () => import('./components/VetsterReviewsCarousel'));
Vue.component('VetsterCitySearchForm', () => import('./components/VetsterCitySearchForm'));
Vue.component('VetsterCityFilter', () => import('./components/VetsterCityFilter'));
Vue.component('VetsterConcernList', () => import('./components/VetsterConcernList'));
Vue.component('VetsterModuleList', () => import('./components/VetsterModuleList'));
Vue.component('VetsterContentAuthGate', () => import('./components/VetsterContentAuthGate'));
Vue.component('VetsterContentBadge', () => import('./components/VetsterContentBadge'));
Vue.component('VetsterGlobalSearch', () => import('./components/GlobalSearch/VetsterGlobalSearch'));
Vue.component('VetsterEducation', () => import('./brand-components/VetsterEducation'));
Vue.component('VetsterEntitySearchV2', () => import('./components/EntityPages/VetsterEntitySearchV2'));
Vue.component('VetsterEntitySearch', () => import('./components/EntityPages/VetsterEntitySearch'));
Vue.component('VetsterGiftUpWidget', () => import('./components/VetsterGiftUpWidget'));
Vue.component('VetsterValentinesDayTerms', () => import('./components/VetsterValentinesDayTerms'));
Vue.component('VetsterNeighborhoodTile', () => import('./components/VetsterNeighborhoodTile'));
Vue.component('VetsterPartnerReferral', () => import('./components/VetsterPartnerReferral'));
Vue.component('VetsterSymptomTable', () => import('./components/VetsterSymptomTable'));
Vue.component('VetsterPromotionSection', () => import('./components/VetsterPromotionSection'));
Vue.component('VetsterOutlineBadge', () => import('./brand-components/VetsterOutlineBadge'));
Vue.component('VetsterCircleAvatar', () => import('./components/VetsterCircleAvatar'));
Vue.component('VetsterPerksSlider', () => import('./components/VetsterPerksSlider'));
Vue.component('VetsterPetConnectionAntiCard', () => import('./components/VetsterPetConnectionAntiCard'));
Vue.component('VetsterPetConnectionPosts', () => import('./components/VetsterPetConnectionPosts'));
Vue.component('VetsterPetConnectionSpeciesFilter', () => import('./components/VetsterPetConnectionSpeciesFilter'));
Vue.component('VetsterPetConnectionStageFilter', () => import('./components/VetsterPetConnectionStageFilter'));
Vue.component('VetsterPromotionCard', () => import('./components/VetsterPromotionCard'));
Vue.component('VetsterPromotionHeader', () => import('./components/VetsterPromotionHeader'));
Vue.component('VetsterRegionCityCard', () => import('./components/VetsterRegionCityCard'));
Vue.component('VetsterSelect', () => import('./components/VetsterSelect'));
Vue.component('VetsterShareModal', () => import('./components/VetsterShareModal'));
Vue.component('VetsterSideCollapse', () => import('./components/VetsterSideCollapse'));
Vue.component('VetsterSideDropdown', () => import('./components/VetsterSideDropdown'));
Vue.component('VetsterSideNav', () => import('./components/VetsterSideNav'));
Vue.component('VetsterSideScroll', () => import('./components/VetsterSideScroll'));
Vue.component('VetsterDynamicStickyNav', () => import('./components/VetsterDynamicStickyNav'));
Vue.component('VetsterStickyDropdown', () => import('./components/VetsterStickyDropdown'));
Vue.component('VetsterStickyNav', () => import('./components/VetsterStickyNav'));
Vue.component('VetsterStickyScroll', () => import('./components/VetsterStickyScroll'));
Vue.component('VetsterTabWrapper', () => import('./components/VetsterTabWrapper'));
Vue.component('VetsterExitIntentSurvey', () => import('./components/VetsterExitIntentSurvey'));
Vue.component('VetsterDynamicGeoVetList', () => import('./components/VetsterDynamicGeoVetList'));
Vue.component('VetsterTopRatedVetsCarousel', () => import('./components/VetsterTopRatedVetsCarousel'));
Vue.component('VetsterGeoVetList', () => import('./components/VetsterGeoVetList'));
Vue.component('VetsterOidcLogin', () => import('./brand-components/VetsterOidcLogin'));
Vue.component('VetsterLocaleChanger', () => import('./components/VetsterLocaleChanger'));
Vue.component('VetsterCityTile', () => import('./components/VetsterCityTile'));
Vue.component('VetsterCitySearch', () => import('./components/VetsterCitySearch'));
Vue.component('VetsterSimpleSearch', () => import('./components/VetsterSimpleSearch'));
Vue.component('VetsterSimpleSearchBar', () => import('./brand-components/VetsterSimpleSearchBar'));
Vue.component('VetsterMembershipSubscriptions', () => import('./components/VetsterMembershipSubscriptions'));
Vue.component('VetsterMembershipOffering', () => import('./components/VetsterMembershipOffering'));
Vue.component('VetsterMembershipOfferingButton', () => import('./brand-components/VetsterMembershipOfferingButton'));
Vue.component('VetsterMembershipSubscriptions', () => import('./components/VetsterMembershipSubscriptions'));
Vue.component('VetsterMembershipTestimonials', () => import('./components/VetsterMembershipTestimonials'));
Vue.component('VetsterProductCategoryTestimonials', () => import('./components/VetsterProductCategoryTestimonials'));
Vue.component('VetsterNotice', () => import('@/brand-components/VetsterNotice'));
Vue.component('VetsterTestimonials', () => import('./components/VetsterTestimonials'));
Vue.component('VetsterRotatingText', () => import('./components/VetsterRotatingText'));
Vue.component('VetsterVersusCompetitorsShowcase', () => import('./components/VetsterVersusCompetitorsShowcase'));
Vue.component('VetsterVersusCompetitorsTable', () => import('./components/VetsterVersusCompetitorsTable'));
Vue.component('VetsterCollapse', () => import('./components/VetsterCollapse'));
Vue.component('VetsterLivechatCTA', () => import('./components/VetsterLivechatCTA'));
Vue.component('VetsterVetSlider', () => import('./components/VetsterVetSlider'));
Vue.component('VetsterSearchTracking', () => import('./components/VetsterSearchTracking'));
Vue.component('VetsterConfirmation', () => import('./brand-components/VetsterConfirmation'));
Vue.component('VetsterReadMore', () => import('./components/VetsterReadMore'));

// Load global plugins.
Vue.use(Analytics);
Vue.use(EventBus);
Vue.use(Layout);
Vue.use(VueCookies);
Vue.use(VueLazyload, { observer: true });
Vue.use(Sentry);

const uat = getCookie('v-uatid');

Vue.use(Api, { uat });
Vue.use(Auth, { uat });

initStatsig(Vue, uat);

// Globally-accessible components (use sparingly).
Vue.component('VetsterButton', VetsterButton);
Vue.component('VetsterButtonLegacy', VetsterButtonLegacy);
Vue.component('VetsterInput', VetsterInput);
Vue.component('VetsterInputLegacy', VetsterInputLegacy);
// Log the user's device info.
storeDeviceData();

// eslint-disable-next-line no-new
new Vue({
  el: '#app',

  name: 'AppRoot',

  components: {
    VetsterFooterLocales,
    VetsterNav,
    VetsterReview,
  },

  mixins: [
    ScrollsToElement,
    TogglesHubSpot,
  ],

  setup() {
    const {
      getViMessage,
      hasViMessage,
      openCookiePreferences,
      removeViMessage,
    } = useCookies();
    const { emitCloseOnEscape } = useKeyboardEvents();
    const { latestPvars } = usePvarsStorage();

    return {
      emitCloseOnEscape,
      getViMessage,
      hasViMessage,
      latestPvars,
      openCookiePreferences,
      removeViMessage,
      snakeCase,
      slugCase,
    };
  },

  store,

  i18n,

  data() {
    return {
      isStatsigHandled: false,
    };
  },

  watch: {
    '$statsig.ready': {
      handler() {
        this.handleStatsigReady();
      },
    },
  },

  created() {
    store.$app = this;
    store.state.$auth = this.$auth;

    this.emitCloseOnEscape();
    this.$analytics.trackUAT(uat);
    fullStoryReadyCallback(this.$sentry);
    fullStoryIdentify();

    this.handleStatsigReady();
  },

  methods: {
    handleStatsigReady() {
      if (!this.isStatsigHandled && this.$statsig.ready) {
        this.isStatsigHandled = true;

        installGooglePlugin();
        this.$analytics.trackPageLoad();
      }
    },
    navigateToSection(id) {
      window.scrollTo({
        top: this.$layout.calculateTop(id),
        behavior: 'smooth',
      });
    },
    trackHomepageCTA(event) {
      event.preventDefault();

      this.$analytics.track({
        eventName: 'homepage_cta_click',
        parent: 'landing-page',
        child: 'homepage',
      });

      window.location.href = event.target.href;
    },
  },
});
