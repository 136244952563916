<script>
import { mapGetters } from 'vuex';

import VetsterReviewCard from '@/brand-components/VetsterReviewCard';

export default {
  name: 'VetsterReview',

  components: { VetsterReviewCard },

  props: {
    perPage: {
      type: Number,
      default: 10,
    },
    reviews: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      moreReviews: [],
    };
  },

  computed: {
    ...mapGetters('reviews', [
      'getCurrentIndex',
      'getNextTenReviews',
    ]),

    canShowMore() {
      return this.getCurrentIndex < this.reviews.length;
    },
  },

  mounted() {
    this.$store.commit('reviews/setReviews', this.reviews);
    // First page is rendered in the Blade template for SEO.
    this.$store.commit('reviews/setCurrentIndex', this.perPage);
  },

  methods: {
    showMore() {
      this.moreReviews = this.moreReviews.concat(this.getNextTenReviews);
      this.$store.commit('reviews/setCurrentIndex', this.getCurrentIndex + this.perPage);
    },
  },
};
</script>
