<template>
  <div
    ref="houseAd"
    :class="containerClasses"
    :data-house-ad="location"
    :data-scroll-padding="scrollPadding"
  >
    <div :class="innerClasses">
      <fa
        v-if="selectedVariant.show_icon"
        :class="iconClasses"
        :icon="['fal', customIcon]"
      />
      <span>
        <span v-html="selectedVariant.text.trim()" />
        <a
          v-if="hasLink"
          :rel="linkRel"
          :href="selectedVariant.link_url"
          :target="linkTarget"
          @click="trackClick"
          v-text="linkText"
        />
      </span>
    </div>
    <button
      v-if="dismissable"
      type="button"
      :aria-label="$t('app.close')"
      :class="closeButtonClasses"
      @click="close"
    >
      <fa
        :icon="['fal', 'xmark']"
        class="md:absolute md:top-1/2 md:-translate-y-1/2 md:right-0"
      />
    </button>
  </div>
</template>

<script>
import HouseAdDismissBehavior from '@/enums/HouseAdDismissBehavior';
import HouseAdFullStripType from '@/enums/HouseAdFullStripType';
import HouseAd from '@/mixins/HouseAd';

export default {
  name: 'VetsterFullStrip',

  mixins: [HouseAd],

  computed: {
    closeButtonClasses() {
      return [
        'relative text-lg ml-6 leading-[unset] flex items-center',
        this.isInfo ? 'text-neutral-50' : '',
        this.isPromo ? 'text-neutral-700' : '',
        this.isPromoLight ? 'text-neutral-700' : '',
        this.isWarning ? 'text-neutral-700' : '',
      ];
    },

    containerClasses() {
      return [
        'w-full px-4 items-start sm:items-center justify-between leading-tight text-sm flex transition-all duration-500 overflow-hidden',
        this.isInfo ? 'bg-ocean-800 text-white' : '',
        this.isPromo ? 'bg-bubblegum-500 text-neutral-900' : '',
        this.isPromoLight ? 'bg-bubblegum-100 text-neutral-900' : '',
        this.isWarning ? 'bg-red-50 text-neutral-900' : '',
        this.isVisible ? this.enterAnimationClasses.join(' ') : this.leaveAnimationClasses.join(' '),
      ];
    },

    customIcon() {
      return this.selectedVariant.custom_icon || HouseAdFullStripType.getIcon(this.selectedVariant.type);
    },

    dismissable() {
      return this.selectedVariant.dismiss_behavior !== HouseAdDismissBehavior.NonDismissable;
    },

    enterAnimationClasses() {
      return ['h-auto', 'py-2.5', 'md:py-3', 'lg:py-2.5', 'visible'];
    },

    hasLink() {
      return this.selectedVariant.link_url && this.selectedVariant.link_text;
    },

    iconClasses() {
      return [
        'size-4 text-xl',
        this.isInfo ? 'text-white' : '',
        this.isPromo ? 'text-neutral-700' : '',
        this.isPromoLight ? 'text-bubblegum-500' : '',
        this.isWarning ? 'text-red-700' : '',
      ];
    },

    innerClasses() {
      return [
        'w-full flex items-center space-x-3 md:space-x-2 leading-5',
        ...this.linkClasses,
        this.selectedVariant.force_center ? 'justify-center text-center' : 'lg:justify-center',
      ];
    },

    isInfo() {
      return this.selectedVariant.type === HouseAdFullStripType.Info || !this.selectedVariant.type;
    },

    isLinkTargetBlank() {
      return this.selectedVariant.link_target === '_blank';
    },

    isPromo() {
      return this.selectedVariant.type === HouseAdFullStripType.Promo;
    },

    isPromoLight() {
      return this.selectedVariant.type === HouseAdFullStripType.PromoLight;
    },

    isWarning() {
      return this.selectedVariant.type === HouseAdFullStripType.Warning;
    },

    leaveAnimationClasses() {
      return ['py-0', 'h-0', 'invisible'];
    },

    linkClasses() {
      return [
        '[&_a]:underline [&_a]:underline-offset-2 [&_a]:font-bold [&_a]:ml-1',
        this.isInfo && '[&_a]:text-white',
        this.isPromo && '[&_a]:text-neutral-900',
        this.isPromoLight && '[&_a]:text-bubblegum-700',
        this.isWarning && '[&_a]:text-neutral-900',
      ].filter(Boolean);
    },

    linkRel() {
      return this.isLinkTargetBlank ? 'noreferrer' : '';
    },

    linkTarget() {
      return this.selectedVariant.link_target || '_self';
    },

    linkText() {
      return this.selectedVariant.link_text;
    },
  },

  beforeDestroy() {
    if (this.isVisible) {
      this.close(false);
    }
  },

  methods: {
    getInitialHeight() {
      const el = this.$refs.houseAd;

      if (!el) {
        return 0;
      }

      // Clone the house ad and append it to the body to get the initial height
      const clone = el.cloneNode(true);

      this.enterAnimationClasses.forEach((className) => clone.classList.add(className));
      this.leaveAnimationClasses.forEach((className) => clone.classList.remove(className));

      clone.style.visibility = 'hidden';

      document.body.appendChild(clone);

      const initialHeight = window.getComputedStyle(clone).height;
      clone.remove();

      return parseInt(initialHeight, 10);
    },
    close(shouldDismiss = true) {
      const {
        classList,
        offsetHeight,
      } = this.$refs.houseAd;
      const mainContentEl = document.getElementById('main-content');

      const adHeight = offsetHeight || this.getInitialHeight(); // Fallback needed for Safari
      // To avoid white background flash on dismiss
      mainContentEl.classList.toggle('duration-500', false);
      this.$bus.$emit('house-ad-dismissed', this.location, adHeight);
      this.isVisible = false;
      this.enterAnimationClasses.forEach((className) => classList.remove(className));
      this.leaveAnimationClasses.forEach((className) => classList.add(className));

      setTimeout(() => {
        mainContentEl.classList.toggle('duration-500', true);

        if (shouldDismiss) {
          this.dismiss();
        }
      }, 500);
    },
  },
};
</script>
