<template>
  <component
    :is="houseAdFormat"
    v-if="hasActiveAd"
    :hash-id="hashId"
    :house-ad="houseAd"
    :location="location"
    :scroll-padding="scrollPadding"
    :selected-variant="selectedVariant"
  />
</template>

<script>
import md5 from 'crypto-js/md5';
import size from 'lodash/size';
import { DateTime } from 'luxon';
import { mapGetters } from 'vuex';

import VetsterFullStrip from '@/brand-components/HouseAd/VetsterFullStrip';
import useBrowserApiHelpers from '@/composables/use-browser-api-helpers';
import HouseAdDismissBehavior from '@/enums/HouseAdDismissBehavior';
import HouseAdFormat from '@/enums/HouseAdFormat';
import HouseAdSelectionStrategy from '@/enums/HouseAdSelectionStrategy';
import HouseAdVariantSelectionStrategy from '@/enums/HouseAdVariantSelectionStrategy';
import parsedLocalStorage from '@/helpers/parsedLocalStorage';
import { removeLocaleFromPath } from '@/helpers/url';

export default {
  name: 'VetsterHouseAd',

  components: { VetsterFullStrip },

  provide() {
    return {
      dismiss: this.dismiss,
      trackClick: this.trackClick,
      trackView: this.trackView,
    };
  },

  props: {
    format: {
      type: String,
      required: true,
    },
    houseAds: {
      type: Array,
      default: () => [],
    },
    location: {
      type: String,
      required: true,
    },
    // Required for Global Search interaction
    scrollPadding: {
      type: Boolean,
      default: false,
    },
    selectionStrategy: {
      type: String,
      default: HouseAdSelectionStrategy.First,
    },
  },

  setup() {
    return { HouseAdFormat };
  },

  data() {
    return {
      dismissedHouseAds: parsedLocalStorage('dismissedHouseAds') || [],
      wasDismissed: false,
    };
  },

  computed: {
    ...mapGetters([
      'getAuthLoaded',
      'getUser',
    ]),

    ...mapGetters('globalSearch', ['isGlobalSearchFocused']),

    experimentName() {
      return `expt_${this.houseAd.name}`;
    },

    filteredHouseAds() {
      const parsedPath = removeLocaleFromPath(window.location.pathname);

      return this.houseAds.filter((houseAd) => this.filterHouseAds(houseAd, parsedPath)
        && this.getAuthLoaded
        && this.$statsig.ready
        && this.$statsig.checkGate(houseAd.name));
    },

    hasActiveAd() {
      return this.houseAd
        && this.selectedVariant
        && !this.isDismissed;
    },

    hashId() {
      return md5(`${this.location}${this.houseAd?.name}${this.houseAd?.format}`).toString();
    },

    houseAdFormat() {
      switch (this.format) {
        case HouseAdFormat.FullStrip:
          return VetsterFullStrip;
        default:
          return null;
      }
    },

    isDismissed() {
      const dismissedAds = this.dismissedHouseAds.filter(
        (ad) => ad.dismiss_behavior === this.selectedVariant.dismiss_behavior && ad.hash_id === this.hashId,
      );

      if (this.isPersistentDismiss) {
        return dismissedAds.length > 0;
      }

      if (this.isTimedDismiss) {
        return dismissedAds.some((ad) => this.isTimedDismissed(ad));
      }

      if (this.isSessionDismiss) {
        return dismissedAds.some(
          (ad) => Object.keys(this.sessionPayload).every((key) => ad[key] === this.sessionPayload[key])
            && (this.selectedVariant.dismiss_behavior !== HouseAdDismissBehavior.TimedSessionDismiss || this.isTimedDismissed(ad)),
        );
      }

      return false;
    },

    isNonDismissable() {
      return this.selectedVariant.dismiss_behavior === HouseAdDismissBehavior.NonDismissable;
    },

    isPersistentDismiss() {
      return this.selectedVariant.dismiss_behavior === HouseAdDismissBehavior.PersistentDismiss;
    },

    isSessionDismiss() {
      return [
        HouseAdDismissBehavior.SessionDismiss,
        HouseAdDismissBehavior.TimedSessionDismiss,
      ].includes(this.selectedVariant.dismiss_behavior);
    },

    isTemporaryDismiss() {
      return this.selectedVariant.dismiss_behavior === HouseAdDismissBehavior.TemporaryDismiss;
    },

    isTimedDismiss() {
      return this.selectedVariant.dismiss_behavior === HouseAdDismissBehavior.TimedDismiss;
    },

    selectFirstVariant() {
      return this.houseAd.variant_selection === HouseAdVariantSelectionStrategy.First;
    },

    selectedVariant() {
      return this.houseAd?.variants[this.variantIndex] ?? null;
    },

    selectVariantFromStatsigExperiment() {
      return this.houseAd.variant_selection === HouseAdVariantSelectionStrategy.StatsigExperiment;
    },

    selectVariantRandomly() {
      return this.houseAd.variant_selection === HouseAdVariantSelectionStrategy.Random;
    },

    sessionPayload() {
      return this.getUser?.uuid
        ? { user_id: this.getUser.uuid }
        : { uat_id: this.$cookies.get('v-uatid') };
    },

    variantIndex() {
      if (this.selectFirstVariant) {
        return 0;
      }

      if (this.selectVariantRandomly) {
        return Math.floor(Math.random() * this.houseAd.variants.length);
      }

      if (this.selectVariantFromStatsigExperiment) {
        const variantName = this.$statsig.getExperiment(this.experimentName).get('value');

        return this.houseAd.variants.findIndex((variant) => variant.name === variantName) ?? 0;
      }

      return -1;
    },

    houseAd() {
      if (this.wasDismissed || size(this.filteredHouseAds) === 0) {
        return null;
      }

      if (this.selectionStrategy === HouseAdSelectionStrategy.Random) {
        return this.filteredHouseAds[Math.floor(Math.random() * this.filteredHouseAds.length)];
      }

      return this.filteredHouseAds[0];
    },
  },

  methods: {
    dismiss() {
      // this.houseAd already dismissed?
      if (!this.selectedVariant) {
        this.wasDismissed = true;

        return;
      }

      if (this.isNonDismissable) {
        return;
      }

      if (!this.isTemporaryDismiss) {
        this.saveDismissed();
      }

      this.trackEvent('house_ad_dismiss', {
        dismiss_behavior: this.selectedVariant.dismiss_behavior,
      });

      this.wasDismissed = true;
    },

    filterHouseAds(houseAd, parsedPath) {
      return this.isFormatAccepted(houseAd)
        && this.isPathAccepted(houseAd, parsedPath)
        && this.isLocationAccepted(houseAd)
        && this.isActive(houseAd);
    },

    isActive(houseAd) {
      const now = DateTime.now();

      return DateTime.fromISO(houseAd.start_at) < now
        && (!houseAd.end_at || DateTime.fromISO(houseAd.end_at) > now);
    },

    isFormatAccepted(houseAd) {
      return houseAd.format === this.format;
    },

    isLocationAccepted(houseAd) {
      return houseAd.pages.some((page) => page.locations.some((location) => location.name === this.location));
    },

    isPathAccepted(houseAd, parsedPath) {
      for (let i = 0; i < houseAd.pages.length; i++) {
        const { path } = houseAd.pages[i];
        const exclude = path.startsWith('!');
        const pattern = new RegExp(`^${exclude ? path.slice(1) : path}$`);

        if (exclude && pattern.test(parsedPath)) {
          return false;
        }

        if (!exclude && pattern.test(parsedPath)) {
          return true;
        }
      }

      return size(houseAd.pages) === 0;
    },

    isTimedDismissed(houseAd) {
      return DateTime.fromISO(houseAd.dismiss_at).plus({
        minutes: this.selectedVariant.dismiss_time || 1440,
      }) >= DateTime.now();
    },

    saveDismissed() {
      const { localStorageAvailable } = useBrowserApiHelpers();

      if (localStorageAvailable()) {
        localStorage.setItem('dismissedHouseAds', JSON.stringify([
          ...this.dismissedHouseAds,
          {
            hash_id: this.hashId,
            dismiss_at: DateTime.now().toISO({ suppressMilliseconds: true }),
            dismiss_behavior: this.selectedVariant.dismiss_behavior,
            ...(this.isSessionDismiss ? this.sessionPayload : {}),
          },
        ]));
      }
    },

    trackClick() {
      this.trackEvent('house_ad_click');

      if (this.selectedVariant.dismiss_on_click) {
        this.saveDismissed();
      }
    },

    trackEvent(eventName, additionalData = {}) {
      this.$analytics.track({
        eventName,
        parent: 'global',
        child: 'webpage',
        name: this.houseAd.name,
        location: this.location,
        format: this.format,
        target_url: this.selectedVariant.link_url,
        ...additionalData,
      });
    },

    trackView() {
      this.trackEvent('house_ad_view');
    },
  },
};
</script>
